import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Lottie from 'react-lottie';

import './diagnocy.css'
import * as animationData from '../images/diagnocy/data.json';

const answers = [
  'アンラッキーを感じたらラッキーの予兆',
  '明日じゃなくって今日やる',
  '今日やることは未来につながる',
  'そうきたか！のビックチャンス到来',
  '昼寝すべし',
  '間違いなんてひとつもない',
  '願って願って思い切っり',
  '叶いまくるフィーバーウェーブデー',
  '人にお願いすべし',
  '「あのね」と自分から切り出す',
  '何もしない空っぽの日にしよう',
  '思い立ったら吉日がまさに今',
  'まわりを気にするな',
  '拾うかも・・・100円',
  '思いっきり甘やかそう',
  'エネルギーが変わる',
  '笑い声が100万人幸せにする',
  '電波から離れる日',
  '大事な出会いが待ってる',
  '私が一番！',
  'そのこだわりが輝きを増す',
  '深呼吸して気分を変えよう',
  '呼吸してる？',
  '寝てる？',
  '食べてる？',
  '遊んでる？',
  '名前を５回声に出そう',
  '名前を７回書こう',
  '名前を３秒間見つめよう',
  '名前を逆さまに５回叫ぼう',
  'あの人に電話をしよう',
  '笑ってごまかしても許される日',
  'トキメキを大事に',
  'いつだって味方だよ',
  '1人じゃない',
  '宇宙も神様も目の前に',
  '才能は爆発だ！',
  'いつだって最高だよ',
  'うわ！最高の日だ',
  'ひとりの時間を持とう',
  '自分の本音に従うべき',
  '新しいルーティンをチョイス',
  'いつもと違うルートを選ぼう',
]

const Modal = ({ isFirst, onClose, result }) => {
  if (isFirst) { return null; }

  return (
    <button className="modal" onClick={onClose}>
      <div className="modal-content">
        <h1>今日のひとこと</h1>
        <p>{result}</p>
      </div>
    </button>
  )
}

class DiagnocyPage extends React.Component {
  state = {
    isStopped: false,
    isPaused: false,
    modal: false,
    isFirst: true,
    username: '',
    result: ''
  }

  onClose = () => {
    this.setState({ modal: false });
  }

  handleKeyDown = event => {
    this.setState({ username: event.target.value });

    if (event.keyCode === 13) {
      this.handleClick();
    }
  }
 
  handleClick = () => {
    const { username } = this.state;

    if (username === '') { return }

    this.setState({ isStopped: true, modal: false, isPaused: false }, () => {
      this.setState({ isStopped: false, isFirst: false });
      this.getDiagnocy(username);
    });
  }

  getDiagnocy = username => {
    let sumOfCharCode = 0;

    for(let i = 0; i < username.length; i++) {
      sumOfCharCode = sumOfCharCode + username.charCodeAt(i);
    }

    const date = new Date();
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1);
    const day = String(date.getDate());
    const today = year + month + day;

    sumOfCharCode = `${sumOfCharCode}${today}`;

    const index = sumOfCharCode % answers.length;
    let result = answers[index];
    this.setState({ result: result });
  }

  render() {
    const { isStopped, isPaused, modal, isFirst, result } = this.state;

    const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    
    const eventListeners = [
      {
        eventName: 'complete',
        callback: () => {
          this.setState({ modal: true, isPaused: true });
        }
      }
    ]

    return ( 
      <Layout>
        <SEO title="お名前診断" />
        <div className="diagnocy content">

          <div className="title-holder">
            <div className="title">
              <p>NAME</p>
              <p>SECRET</p> 
              <p>CORD</p>
            </div>
            <div className="cap">
              <p>ローマ字でおなまえを入力して</p>
              <p>診断スタート！</p>
            </div>
          </div>

          <div className="lottie-holder">
            <Lottie
               options={defaultOptions}
               isStopped={isStopped}
               isPaused={isPaused}
               eventListeners={eventListeners}
            />
          </div>
          
          <div className="input-holder">
            <div className="label">Name</div>
            <div className="input-holder">
              <input
                type="text"
                name="fname"
                onKeyDown={this.handleKeyDown}
               />
            </div>
          </div>

          <div className="btn-holder">
            <button onClick={this.handleClick}>
              診断START
            </button>
          </div>

          {modal ? 
            <Modal
              isFirst={isFirst}
              result={result}
              onClose={this.onClose}
             />
            : 
            null
          }

        </div>
      </Layout>
    )
  }
}

export default DiagnocyPage
